



















import Vue from 'vue';
import { copyTextToClipboard } from '@/services/clipboardService';
import AppButton from '@/components/atoms/appButton/AppButton.vue';

export default Vue.extend({
    name: 'ApplicationFormSchemaTemplate',
    components: {
        AppButton,
    },
    props: {
        schema: {
            type: Object,
            required: true,
        },
    },
    computed: {
        schemaString(): string {
            return JSON.stringify(this.schema);
        },
    },
    methods: {
        copyToClipboard(): void {
            copyTextToClipboard(this.schemaString);
        },
    },
});
