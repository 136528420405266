import { render, staticRenderFns } from "./ApplicationFormSchemaTemplate.vue?vue&type=template&id=c502d332&scoped=true&"
import script from "./ApplicationFormSchemaTemplate.vue?vue&type=script&lang=ts&"
export * from "./ApplicationFormSchemaTemplate.vue?vue&type=script&lang=ts&"
import style0 from "./ApplicationFormSchemaTemplate.vue?vue&type=style&index=0&id=c502d332&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c502d332",
  null
  
)

export default component.exports