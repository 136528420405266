import { JSONSchema7Definition } from 'json-schema';
import { defaults as _defaults } from 'lodash';
import {
    FormFieldConfig,
    formFieldDefaultConfig,
    FormField,
} from './formField';

export interface TextFieldConfig extends FormFieldConfig<string> {
    value?: string;
}

export const textFieldDefaultConfig = {
    ...formFieldDefaultConfig,
};

export class TextField extends FormField<string> {
    public constructor(config?: TextFieldConfig) {
        const resolvedConfig = _defaults(
            config || ({} as TextFieldConfig),
            textFieldDefaultConfig,
        );

        super(resolvedConfig);
    }

    public getJsonSchema(): JSONSchema7Definition {
        return {
            type: 'string',
        };
    }
}
