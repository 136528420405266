import { JSONSchema7 } from 'json-schema';
import { Form } from '@/types/forms/form';
import { FormGroup } from '@/types/forms/formGroup';
import { FormField } from '@/types/forms/fields/formField';
import { FormStep } from '@/types/forms/formStep';
import { getFormDtoAttributeData } from '@/types/forms/formDtoAttribute';
import { FormList } from '@/types/forms/fields/formList';

const additionalProperties = false;

/**
 * Walks through form groups of this form step/form group and returns the JSON schema
 * from the form fields. Recursively calls itself to handle sub form groups.
 */
function getJsonSchemaForFormGroupAndElement(
    formGroup: FormGroup,
): JSONSchema7 {
    const schema: JSONSchema7 = {
        additionalProperties,
    };

    const properties = schema.properties || (schema.properties = {});

    for (const prop in formGroup) {
        if (Object.prototype.hasOwnProperty.call(formGroup, prop)) {
            const formGroupProp = formGroup[prop as keyof FormGroup];
            if (formGroupProp instanceof FormList) {
                properties[prop] = {
                    ...getJsonSchemaForValue(formGroupProp.items),
                    ...getJsonSchemaForFormGroupAndElement(
                        new formGroupProp.itemType(formGroupProp.config),
                    ),
                };
            } else if (formGroupProp instanceof FormGroup) {
                properties[prop] = getJsonSchemaForFormGroupAndElement(
                    formGroupProp,
                );
            } else if (formGroupProp instanceof FormField) {
                properties[prop] = formGroupProp.getJsonSchema();
            }
        }
    }

    return schema;
}

function getJsonSchemaForValue(value: unknown): JSONSchema7 {
    if (Array.isArray(value)) {
        return {
            type: 'array',
        };
    }

    const schema: JSONSchema7 = {};
    const type = typeof value;
    switch (type) {
        case 'string':
        case 'number':
        case 'boolean':
        case 'object':
            schema.type = type;
    }
    return schema;
}

export function getJsonSchemaForForm(form: Form): JSONSchema7 {
    const schema: JSONSchema7 = {
        additionalProperties,
    };

    const properties = schema.properties || (schema.properties = {});

    for (const prop in form) {
        const formProp = form[prop as keyof Form] as unknown;
        const dtoAttributeData = getFormDtoAttributeData(prop);
        if (dtoAttributeData && dtoAttributeData.jsonSchema) {
            properties[prop] = dtoAttributeData.jsonSchema;
        } else if (formProp instanceof FormStep) {
            properties[prop] = getJsonSchemaForFormGroupAndElement(formProp);
        }
    }

    return schema;
}
