function fallbackCopyTextToClipboard(text: string): void {
    // create dummy textarea to have something to select the text from
    const textarea = document.createElement('textarea');
    textarea.value = text;
    textarea.style.top = '0';
    textarea.style.left = '0';
    textarea.style.position = 'fixed';
    document.body.appendChild(textarea);
    textarea.focus();
    textarea.select();

    // copy the selected text
    document.execCommand('copy');

    // get rid of the textarea again
    document.body.removeChild(textarea);
}

export async function copyTextToClipboard(text: string): Promise<void> {
    navigator.clipboard
        ? await navigator.clipboard.writeText(text)
        : fallbackCopyTextToClipboard(text);
}
