




import Vue from 'vue';
import ApplicationFormSchemaTemplate from '@/components/templates/dummy/applicationFormSchemaTemplate/ApplicationFormSchemaTemplate.vue';
import { modelService } from '@/services/form/modelService';
import { getJsonSchemaForForm } from '@/services/form/schemaGeneration';

export default Vue.extend({
    name: 'ApplicationFormSchema',
    components: {
        ApplicationFormSchemaTemplate,
    },
    computed: {
        schema() {
            return getJsonSchemaForForm(modelService.model.application);
        },
    },
});
